import { display, fonts, media } from 'themes'

import { Card } from '@nv/react-akira'
import { Text } from '@app/components/index'
import styled from 'styled-components'

const OptionsContainerWrapper = styled.div`
  text-align: left;
  font-family: "Noto Sans";
  padding: 4px 0px;
  margin: 0 auto;
  width: 80%;
  ${media.max.mobile`width: ${props => props.isCustomerCollect ? '100%' : '80%'}`};
  
  @media (max-width:960px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width:1200px) {
    width: 720px;
  }
`

const OptionsCardContainer = styled(Card)`
  width:100%;
  border: none;
  border-radius: 10px;
  ${props => props.showBulkUploadForm ? 'padding: 24px' : 'padding: 30px 24px'};
  
`

const OptionsContainer = styled.div`
  @media (min-width:960px) {
    ${display.flex('stretch', 'flex-start', 'row')};
    ${display.childMargins('column', 15)};
  }

  @media (max-width:960px) {
    display: flex;
    flex-direction:${props => props.isCustomerCollect ? 'row' : 'column'};
    gap: 15px; 
  }

`

const OptionCard = styled(Card)`
  & h4 {
    width: 100%;
  }
  && .layout-wrapper {
    max-width: 32vw;
  }
`

const CustomSubHeaderText = styled(Text)`
  ${fonts.size.medium};
`
const CustomInfoText = styled(Text)`
  ${fonts.size.subHeading};
  &&.collect-options-sub-title {
    margin-bottom: 30px;
  }
`
const FormItemWrapper = styled.div`
  margin-top: 22px !important;
  &.resend-otp-link {
    display: flex;
    .resend-button {
      padding-left: 8px
    }
  }
}
`

const StyledTitleText = styled(Text)`
  margin: 0px 0px 16px 0px;
  ${media.max.tablet`margin: 24px 0px 16px 0`};
  ${media.max.mobile`margin: 24px 0px 16px 25px`};
`

export {
  OptionsContainerWrapper,
  CustomInfoText,
  OptionsCardContainer,
  OptionsContainer,
  OptionCard,
  CustomSubHeaderText,
  FormItemWrapper,
  StyledTitleText
}
