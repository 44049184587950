import { selectCountry, selectDpId } from '@app/containers/Base/selectors'
import _ from 'lodash'
import { baseTypes } from '@app/containers/Base/redux'

export default store => next => action => {
  try {
    const actionType = action.type

    if (actionType === baseTypes.SUCCESS_LOGIN) {
      const dpId = _.get(action, 'loginInfo.dpId')
      const systemId = _.get(action, 'loginInfo.systemId')
      setDpId(dpId)
      setSystemId(systemId)
    }

    if (actionType === baseTypes.LOGOUT) {
      unsetDpId()
      unsetSystemId()
    }

    // we need this since "page refresh" resets the dataLayer
    if (actionType === '@@router/LOCATION_CHANGE') {
      const dpId = selectDpId()(store.getState())
      const systemId = selectCountry()(store.getState())
      setDpId(dpId)
      setSystemId(systemId)
    }

    return next(action)
  } catch (_) {
    return next(action)
  }
}

const setDpId = dpId => {
  if (window.dataLayer) {
    window.dataLayer.push({ dpId })
  }
}

const unsetDpId = () => {
  if (window.dataLayer) {
    window.dataLayer.push({ dpId: undefined })
  }
}

const setSystemId = systemId => {
  if (window.dataLayer) {
    window.dataLayer.push({ systemId })
  }
}

const unsetSystemId = () => {
  if (window.dataLayer) {
    window.dataLayer.push({ systemId: undefined })
  }
}
