import {
  ConsoleInstrumentation,
  ConsoleTransport,
  ErrorsInstrumentation,
  FetchTransport,
  SessionInstrumentation,
  WebVitalsInstrumentation,
  initializeFaro
} from '@grafana/faro-web-sdk'

import Config from 'configs'
import { ReactIntegration } from '@grafana/faro-react'

const collectorUrl = Config.GRAFANA_FARO_URL
const apiKey = Config.GRAFANA_FARO_API_KEY

export function initialiseFaro () {
  if (collectorUrl && apiKey) {
    const faro = initializeFaro({
      instrumentations: [
        new ErrorsInstrumentation(),
        new WebVitalsInstrumentation(),
        new ConsoleInstrumentation({
          disabledLevels: []
        }),
        new SessionInstrumentation(),
        new ReactIntegration()
      ],
      sessionTracking: {
        enabled: true,
        persistent: true,
        session: window.__PRELOADED_STATE__?.faro?.session
      },
      metas: [],
      transports: [
        new FetchTransport({
          url: collectorUrl || '',
          apiKey: apiKey
        }),
        new ConsoleTransport()
      ],
      app: {
        name: 'ninja-point-v3',
        version: '1.0.0',
        environment: Config.ENVIRONMENT
      }
    })

    faro.api.pushLog(['Faro was initialized'])

    return faro
  }
  return
}
