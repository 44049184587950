import { display } from '@app/themes'
import styled from 'styled-components'

const BulkUploadOrdersWrapper = styled.div`
  && {
    font-family: "Noto Sans" !important;
    ${display.childMargins('row')};
  }
`

export {
  BulkUploadOrdersWrapper
}
