/**
 *
 * Text
 *
 */

import { colors, fonts } from '@app/themes'
import styled, { css } from 'styled-components'

import PropTypes from 'prop-types'
import React from 'react'
import { T } from '@nv/rc/Components'

const types = {
  primary: css`
    color: ${colors.nvPriRed};
  `,
  secondary: css`
    color: ${colors.greyishBrown};
  `,
  tertiary: css`
    color: ${colors.pinkishGrey};
  `,
  title: css`
    color: ${colors.greyishBrown};
    margin-bottom: 16px;
    ${fonts.size.big};
    ${fonts.style.bold};
  `,
  label: css`
    color: ${colors.pinkishGrey};
  `
}
const StyledText = styled.div`
  ${props => fonts.dynamicFontSize(fonts.size[props.size], props.dynamicFontDiff)};
  ${props => fonts.style[props.textStyle]};
  ${props => types[props.type]};
  ${props => props.color && `color: ${props.color}`};
  ${props => props.centered && `text-align: center`};
  padding: ${props => props.padding}px;
  text-transform: ${props => props.textTransform};
  word-break: break-word;
  letter-spacing: ${props => props.letterSpacing};
  text-align: ${props => props.textAlign};
`

class Text extends React.PureComponent {
  render () {
    const { textId, values, children } = this.props
    return <StyledText {...this.props}>{textId ? <T id={textId} values={values} /> : children}</StyledText>
  }
}

Text.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(Object.keys(fonts.size)),
  textStyle: PropTypes.oneOf(Object.keys(fonts.style)),
  type: PropTypes.oneOf(Object.keys(types)),
  dynamicFontDiff: PropTypes.number
}

Text.defaultProps = {
  size: 'medium',
  textStyle: 'regular'
}

export { Text }
