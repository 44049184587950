// @flow

import _ from 'lodash'
import moment from 'moment'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'

import pricingInfoUtils from '@app/utils/pricingInfoUtils'
import { parsePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'
import { reservations, shippers } from '@app/constants'
import {
  COUNTRIES,
  COUNTRY_DATA_KEYS,
  DELIVERY_TYPES,
  EMAIL_VALIDATION_REGEX,
  ID_TYPES,
  OC_STATUS,
  ORDER_STATUS,
  PACK_FORM_FIELDS,
  SERVICE_LEVELS,
  getCountryData,
  isEqual,
  normalizePhoneNumber
} from './constants'

import { DATE_FORMAT } from '@app/constants/dates'
import type { BulkPostOrderCsvRow } from '@app/types/BulkPostOrderCsvRow'
import type { DpInfo } from '@app/types/DpInfo'
import type { Order } from '@app/types/Order'
import type { PostOrderRequestPayload } from '@app/types/PostOrderRequestPayload'
import type { PostOrderResponse } from '@app/types/PostOrderResponse'
import type { PricingInfoResponse } from '@app/types/PricingInfoResponse'
import type { SenderDetailsResponse as SenderDetails } from '@app/types/SenderDetailsResponse'
const { ORDER_TYPES } = reservations

const REQUIRED_ORDER_DETAILS = {
  // shipperId: 'senderDetails.id',
  senderName: 'senderDetails.name',
  senderEmail: 'senderDetails.liaisonEmail',
  senderContact: 'senderDetails.liaisonContact',
  senderAddress1: 'senderDetails.liaisonAddress',
  senderPostCode: 'senderDetails.liaisonPostcode',
  parcels: 'parcels'
}
const REQUIRED_PACK_PHOTO_DETAILS = {
  trackingId: 'trackingId',
  image: 'recipientDetails.image.url'
}

const REQUIRED_MANUAL_PACK_PARCEL_DETAILS = {
  packId: 'trackingId',
  postalCode: 'postalCode',
  lat: 'location.lat',
  lng: 'location.lng',
  recipientName: 'recipientDetails.name',
  recipientAddress: 'recipientDetails.address',
  recipientPostCode: 'recipientDetails.postCode'
}

const REQUIRED_DRAFT_POST_PARCEL_DETAILS = {
  stampId: 'stampId',
  lat: 'location.lat',
  lng: 'location.lng',
  weight: 'weight',
  deliveryType: 'deliveryType',
  recipientName: 'recipientDetails.name'
}

const REQUIRED_CONFIRM_POST_PARCEL_DETAILS = {
  ...REQUIRED_DRAFT_POST_PARCEL_DETAILS,
  recipientContact: 'recipientDetails.contact',
  recipientPostCode: 'recipientDetails.postCode',
  recipientAddress: 'recipientDetails.address'
}

const REQUIRED_QUICK_ADDRESS_FIELDS = {
  recipientState: 'location.state',
  recipientCity: 'location.city',
  recipientDistrict: 'location.district'
}

const REQUIRED_CONFIRM_POST_PARCEL_DETAILS_PH = {
  ...REQUIRED_CONFIRM_POST_PARCEL_DETAILS,
  insuredValue: 'insuredValue'
}

const REQUIRED_CONFIRM_POST_PARCEL_DETAILS_ID = {
  ...REQUIRED_CONFIRM_POST_PARCEL_DETAILS,
  pickUpInstruction: 'description'
}

export function constructOrder (bulkPostOrderCsvRow: BulkPostOrderCsvRow): Order {
  return {
    cod: bulkPostOrderCsvRow.cod > 0,
    codValue: bulkPostOrderCsvRow.cod,
    comments: bulkPostOrderCsvRow.delivery_instruction,
    deliveryType: bulkPostOrderCsvRow.delivery_type,
    insurance: bulkPostOrderCsvRow.insured_value > 0,
    insuredValue: bulkPostOrderCsvRow.insured_value,
    description: bulkPostOrderCsvRow.pickup_instruction,
    recipientDetails: {
      address: bulkPostOrderCsvRow.to_address1,
      contact: bulkPostOrderCsvRow.to_contact,
      email: bulkPostOrderCsvRow.to_email,
      name: bulkPostOrderCsvRow.to_name,
      postCode: bulkPostOrderCsvRow.to_postcode
    },
    cost: {
      l1_id: bulkPostOrderCsvRow.l1_id
    },
    senderId: parseInt(bulkPostOrderCsvRow.shipper_id),
    stampId: {
      id: bulkPostOrderCsvRow.stamp_id
    },
    weight: bulkPostOrderCsvRow.weight,
    signature: bulkPostOrderCsvRow.signature
  }
}

export function constructPostOrderRequestPayload (
  orders: Array<[Order, SenderDetails]>,
  dpInfo: DpInfo,
  country: string,
  isDraft: boolean,
  isBulkOrderCreation: boolean,
  isQuickAddressFeatureEnabled = false
): PostOrderRequestPayload {
  // Since we assume that we are sending 1 OC request per shipper
  // i.e all orders here belong to the same shipper
  // We can also get shipper id from any order
  const firstOrder = orders[0]
  const firstOrderOrderDetails = firstOrder[0]
  const firstOrderSenderDetails = firstOrder[1]

  const shipperId = firstOrderSenderDetails.id
  const globalId = firstOrderSenderDetails.globalId

  let signature = _.get(firstOrderSenderDetails, 'signature')
  // If shipper is non-preauthorised, get one-time signature from any of the orders
  if (firstOrderOrderDetails.signature) {
    signature = firstOrderOrderDetails.signature
  }

  // Only suport consignee info population for creating single/multiple non-staging order(s) via NPv3 UI
  // Currently, no support for staging orders and order creation via bulk order CSV upload
  const supportConsigneeInfoPopulation = !isDraft && !isBulkOrderCreation

  return {
    dp_id: dpInfo.id,
    is_draft: isDraft,
    support_consignee_info_population: supportConsigneeInfoPopulation,
    support_quick_address: isQuickAddressFeatureEnabled,
    shipper_id: shipperId,
    global_shipper_id: globalId,
    signature,
    reservations: orders.map((tuple: [Order, SenderDetails]) => {
      const order: Order = tuple[0]
      const senderDetails = tuple[1]

      const deliveryType = order.deliveryType
      const serviceLevelKey = _.invert(DELIVERY_TYPES)[deliveryType]

      return {
        cod: order.codValue,
        delivery_instruction: order.comments,
        delivery_type: deliveryType,
        insured_value: order.insuredValue,
        pickup_instruction: order.description,
        recipient: {
          address1: order.recipientDetails.address,
          address2: order.recipientDetails.address2,
          city: order.location ? order.location.city : undefined,
          contact: appendCountryCodeToNumber(order.recipientDetails.contact, country),
          country,
          email: order.recipientDetails.email,
          latitude: order.location ? order.location.lat : undefined,
          locality: order.location ? order.location.district : undefined,
          longitude: order.location ? order.location.lng : undefined,
          name: order.recipientDetails.name,
          postcode: order.recipientDetails.postCode,
          state: order.location ? order.location.state : undefined,
          consignee_info_id: order.consigneeInfoId || null,
          billing_zone: order?.cost?.billing_zone,
          billing_zone_metadata: order?.cost?.billing_zone_metadata,
          l1_id: order?.cost?.l1_id,
          l2_id: order?.cost?.l2_id,
          l3_id: order?.cost?.l3_id
        },
        sender: {
          address1: senderDetails.liaisonAddress,
          address2: undefined,
          city: dpInfo.city,
          contact: senderDetails.liaisonContact,
          country,
          email: senderDetails.liaisonEmail,
          latitude: dpInfo.latitude,
          longitude: dpInfo.longitude,
          name: senderDetails.name,
          postcode: senderDetails.liaisonPostcode
        },
        service_level: SERVICE_LEVELS[serviceLevelKey],
        stamp_id: order.stampId.id,
        weight: order.weight
      }
    })
  }
}

export function constructPostOrderAsyncRequestPayload (
  orders: Array<[Order, SenderDetails]>,
  dpInfo: DpInfo,
  country: string,
  isDraft: boolean,
  isBulkOrderCreation: boolean,
  isQuickAddressFeatureEnabled = false
): PostOrderAsyncRequestPayload {
  // Since we assume that we are sending 1 OC request per shipper
  // i.e all orders here belong to the same shipper
  // We can also get shipper id from any order
  const firstOrder = orders[0]
  const firstOrderOrderDetails = firstOrder[0]
  const firstOrderSenderDetails = firstOrder[1]

  const globalShipperId = firstOrderSenderDetails.globalId

  let signature = _.get(firstOrderSenderDetails, 'signature')
  // If shipper is non-preauthorised, get one-time signature from any of the orders
  if (firstOrderOrderDetails.signature) {
    signature = firstOrderOrderDetails.signature
  }

  // Only suport consignee info population for creating single/multiple non-staging order(s) via NPv3 UI
  // Currently, no support for staging orders and order creation via bulk order CSV upload
  const supportConsigneeInfoPopulation = !isDraft && !isBulkOrderCreation

  return {
    dp_id: dpInfo.id,
    is_draft: isDraft,
    support_consignee_info_population: supportConsigneeInfoPopulation,
    support_quick_address: isQuickAddressFeatureEnabled,
    global_shipper_id: globalShipperId,
    signature,
    reservations: orders.map((tuple: [Order, SenderDetails]) => {
      const order: Order = tuple[0]
      const senderDetails = tuple[1]

      const deliveryType = order.deliveryType
      const serviceLevelKey = _.invert(DELIVERY_TYPES)[deliveryType]

      return {
        cod: order.codValue,
        delivery_instruction: order.comments,
        delivery_type: deliveryType,
        insured_value: order.insuredValue,
        pickup_instruction: order.description,
        recipient: {
          address1: order.recipientDetails.address,
          address2: order.recipientDetails.address2,
          city: order.location ? order.location.city : undefined,
          contact: appendCountryCodeToNumber(order.recipientDetails.contact, country),
          country,
          email: order.recipientDetails.email,
          latitude: order.location ? order.location.lat : undefined,
          locality: order.location ? order.location.district : undefined,
          longitude: order.location ? order.location.lng : undefined,
          name: order.recipientDetails.name,
          postcode: order.recipientDetails.postCode,
          state: order.location ? order.location.state : undefined,
          consignee_info_id: order.consigneeInfoId || null,
          billing_zone: order?.cost?.billing_zone,
          billing_zone_metadata: order?.cost?.billing_zone_metadata,
          l1_id: order?.cost?.l1_id,
          l2_id: order?.cost?.l2_id,
          l3_id: order?.cost?.l3_id
        },
        sender: {
          address1: senderDetails.liaisonAddress,
          address2: undefined,
          city: dpInfo.city,
          contact: senderDetails.liaisonContact,
          country,
          email: senderDetails.liaisonEmail,
          latitude: dpInfo.latitude,
          longitude: dpInfo.longitude,
          name: senderDetails.name,
          postcode: senderDetails.liaisonPostcode
        },
        service_level: SERVICE_LEVELS[serviceLevelKey],
        stamp_id: order.stampId.id,
        weight: order.weight
      }
    })
  }
}


export function enrichOrderWithPostOrderResponse (
  order: Order,
  postOrderResponse: PostOrderResponse,
  senderDetails: SenderDetails
): Order {
  const pricingInfoResponse: PricingInfoResponse =
    postOrderResponse && postOrderResponse.data ? postOrderResponse.data.reservation.pricing_info : {}

  const ocStatus = !postOrderResponse.error ? OC_STATUS.success : OC_STATUS.failed
  const ocMessage = postOrderResponse.error
    ? postOrderResponse.error?.error_details?.error.message || postOrderResponse.error?.message
    : ''

  return {
    ...order,
    cost: {
      cod: {
        amount: pricingInfoUtils.getCodFeeAmount(pricingInfoResponse),
        taxAmount: pricingInfoUtils.getCodFeeTaxAmount(pricingInfoResponse)
      },
      insuranceFee: {
        amount: pricingInfoUtils.getInsuranceFeeAmount(pricingInfoResponse),
        taxAmount: pricingInfoUtils.getInsuranceFeeTaxAmount(pricingInfoResponse)
      },
      deliveryFee: pricingInfoUtils.calculateDeliveryFee(pricingInfoResponse),
      insurance: pricingInfoUtils.calculateInsurance(pricingInfoResponse),
      total: pricingInfoUtils.calculateTotal(pricingInfoResponse),
      totalWithTax: pricingInfoUtils.getTotalWithTax(pricingInfoResponse)
    },
    oc: {
      message: ocMessage,
      status: ocStatus
    },
    ...order.receipt,
    senderDetails,
    trackingId: postOrderResponse.data ? postOrderResponse.data.reservation.barcode : ''
  }
}

export const constructSenderDetails = (orderDetails: Order) => ({
  id: _.get(orderDetails, 'shipperId', ''),
  name: _.get(orderDetails, 'shipperName', ''),
  globalId: _.get(orderDetails, 'globalShipperId', ''),
  liaisonEmail: _.get(orderDetails, 'shipperEmail', ''),
  liaisonContact: _.get(orderDetails, 'shipperContact', ''),
  liaisonAddress: _.get(orderDetails, 'shipperAddress', ''),
  isPrepaid: _.get(orderDetails, 'isPrepaidShipper', shippers.IS_PREPAID_DEFAULT)
})

export const constructRecipientDetails = (orderDetails: Order, country: string) => {
  const parsedContact = parsePhoneNumber(_.get(orderDetails, 'toContact', ''), _.toUpper(country))
  return {
    name: _.get(orderDetails, 'toName', ''),
    email: _.get(orderDetails, 'toEmail', ''),
    contact: _.get(parsedContact, 'nationalNumber', ''),
    address: _.get(orderDetails, 'toAddress1', ''),
    address2: _.get(orderDetails, 'toAddress2', ''),
    postCode: _.get(orderDetails, 'toPostcode', '')
  }
}

export const constructEditOrderDetails = (orderDetails: Order, country: string) => {
  return {
    source: _.get(orderDetails, 'source', ''),
    orderId: _.get(orderDetails, 'orderId', ''),
    receiptId: _.get(orderDetails, 'receiptId', ''),
    status: _.get(orderDetails, 'granularStatus', ''),
    senderDetails: constructSenderDetails(orderDetails),
    parcels: [
      {
        insurance: _.get(orderDetails, 'insuredValue', 0) > 0,
        insuredValue: _.get(orderDetails, 'insuredValue', 0),
        weight: _.get(orderDetails, 'weight', 1),
        deliveryType: _.get(orderDetails, 'deliveryType', DELIVERY_TYPES.STANDARD),
        description: _.get(orderDetails, 'description', ''),
        codValue: _.get(orderDetails, 'cod', 0),
        location: {
          state: _.get(orderDetails, 'toState', ''),
          city: _.get(orderDetails, 'toCity', ''),
          district: _.get(orderDetails, 'toDistrict', ''),
          lat: _.get(orderDetails, 'toLat', ''),
          lng: _.get(orderDetails, 'toLng', ''),
          billing_zone: orderDetails?.toBillingZone,
          billing_zone_metadata: {
            l1_tier: orderDetails?.toBillingZoneMetadata?.l1Tier,
            l2_tier: orderDetails?.toBillingZoneMetadata?.l2Tier
          },
          l1_id: orderDetails?.toL1Id,
          l2_id: orderDetails?.toL2Id,
          l3_id: orderDetails?.toL3Id
        },
        comments: _.get(orderDetails, 'comments', ''),
        stampId: {
          id: _.get(orderDetails, 'stampId', ''),
          isVerified: true
        },
        receipts: _.get(orderDetails, 'receipts', ''),
        trackingId: _.get(orderDetails, 'trackingId', ''),
        recipientDetails: constructRecipientDetails(orderDetails, country)
      }
    ]
  }
}

// Temporary solution from
// https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-currency-string-in-javascript
//
// TODO: use Intl.NumberFormat to format currency
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
export function formatCurrencyNumber (number: number) {
  return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function formatNumber (number: number) {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function FormatOrderCost (country: string, cost: number) {
  const formattedCost = cost ? formatCurrencyNumber(cost) : '0.00'
  return getCountryData(country, COUNTRY_DATA_KEYS.currency) + formattedCost
}
export function FormatOrderCostOrNull (country: string, cost: number) {
  return cost ? getCountryData(country, COUNTRY_DATA_KEYS.currency) + formatCurrencyNumber(cost) : null
}
export function FormatOrderCostWithCurrencyCode (country: string, cost: number) {
  const formattedCost = cost ? formatCurrencyNumber(cost) : '0.00'
  return getCountryData(country, COUNTRY_DATA_KEYS.currencyCode).concat(' ', formattedCost)
}

export function getCodServicePercentage (totalCod: number, totalCodFee: number) {
  return (totalCodFee / totalCod) * 100 && parseInt((totalCodFee / totalCod) * 100) === (totalCodFee / totalCod) * 100
    ? parseInt((totalCodFee / totalCod) * 100)
    : ((totalCodFee / totalCod) * 100).toFixed(2)
}

export function customEmailValidator (type: any, field: any, callback: any, errorText: any) {
  let errors = []
  if (field && !EMAIL_VALIDATION_REGEX.test(field)) {
    errors.push(new Error(errorText))
  }
  callback(errors)
}

export function isValidOrder (order: any, callback: any) {
  const REQUIRED_FIELDS = Object.values(REQUIRED_ORDER_DETAILS)
  const errorFields = checkRequiredFields(order, REQUIRED_FIELDS)
  errorFields.length && callback && callback(errorFields)
  return !errorFields.length
}
export function isValidPackParcel (parcel, isManualPack, callback, country) {
  let requiredFieldsMap = isManualPack ? REQUIRED_MANUAL_PACK_PARCEL_DETAILS : REQUIRED_PACK_PHOTO_DETAILS

  // Lat/lng coordinates not required for PACK since shippers have already paid for them, no need to calculate price
  // PH has a special flow for PACK, see DP-7195
  if (country !== COUNTRIES.PH) {
    delete requiredFieldsMap.lat
    delete requiredFieldsMap.lng
  }
  const requiredFields = Object.values(requiredFieldsMap)
  const errorFields = checkRequiredFields(parcel, requiredFields)

  errorFields.length && callback && callback(errorFields)
  return !errorFields.length
}

function getRequiredFields (country) {
  if (isEqual(country, COUNTRIES.PH)) return REQUIRED_CONFIRM_POST_PARCEL_DETAILS_PH
  if (isEqual(country, COUNTRIES.ID)) return REQUIRED_CONFIRM_POST_PARCEL_DETAILS_ID
  return REQUIRED_CONFIRM_POST_PARCEL_DETAILS
}

export function isValidPostParcel (
  parcel,
  isDraft,
  isEdit,
  country,
  callback,
  isQuickAddressFeatureEnabled = false,
  isStampIdMandatory = true
): boolean {
  let requiredFieldsMap = isDraft ? REQUIRED_DRAFT_POST_PARCEL_DETAILS : getRequiredFields(country)
  // Quick Address feature uses L1/L2/L3 addresses which map to existing State/City/District fields
  // Latitude/Longitude are no longer used for Quick Address
  if (isQuickAddressFeatureEnabled) {
    delete requiredFieldsMap.lat
    delete requiredFieldsMap.lng
    if (!isDraft && !isEdit) {
      requiredFieldsMap = {
        ...requiredFieldsMap,
        ...REQUIRED_QUICK_ADDRESS_FIELDS
      }
    }
  }
  let requiredFields = Object.values(requiredFieldsMap)

  const errorFields = checkRequiredFields(parcel, requiredFields, isEdit, isStampIdMandatory)
  callback && callback(errorFields)
  return !errorFields.length
}

// Do not show validation error for empty stamp id if optional stamp id is supported
function checkRequiredFields (item, requiredFields, isEdit, isStampIdMandatory) {
  const errorFields = []
  requiredFields.forEach((field: any) => {
    if (field === REQUIRED_DRAFT_POST_PARCEL_DETAILS.stampId) {
      if ((isStampIdMandatory && !_.get(item, 'stampId.id')) || !_.get(item, 'stampId.isVerified')) {
        !isEdit && errorFields.push(field)
      }
    }

    if (!_.get(item, field)) {
      if (isEqual(field, 'location.lat') || isEqual(field, 'location.lng')) {
        !isEdit && errorFields.push('location.state', 'location.city', 'location.district')
      } else {
        errorFields.push(field)
      }
    }
  })
  return errorFields
}

export function getOCSenderPayload (senderDetails: any, dpInfo: any, country: any) {
  return {
    global_shipper_id: _.get(senderDetails, 'globalId'),
    from_name: _.get(senderDetails, 'name'),
    from_email: _.get(senderDetails, 'liaisonEmail'),
    from_contact: _.get(senderDetails, 'liaisonContact'),
    from_address1: _.get(senderDetails, 'liaisonAddress'),
    from_address2: _.get(senderDetails, 'liaisonAddress2', ''),
    from_postcode: _.get(senderDetails, 'liaisonPostcode'),
    from_country: country,
    from_city: _.get(dpInfo, 'city'),
    from_latitude: _.get(dpInfo, 'latitude'),
    from_longitude: _.get(dpInfo, 'longitude')
  }
}

// TODO: remove dpInfo param since it is no longer being used
export function getOCPostParcelPayload (parcel: any, dpInfo: any, country: any) {
  const deliveryType = _.get(parcel, 'deliveryType')
  const serviceLevelKey = _.invert(DELIVERY_TYPES)[deliveryType]
  const formattedRecipientNumber = parsePhoneNumberFromString(
    _.get(parcel, 'recipientDetails.contact', ''),
    _.upperCase(country)
  )
  return {
    tracking_id: _.get(parcel, 'trackingId'),
    stamp_id: _.get(parcel, 'stampId.id'),
    to_name: _.get(parcel, 'recipientDetails.name'),
    to_contact: _.get(formattedRecipientNumber, 'number'),
    to_email: _.get(parcel, 'recipientDetails.email'),
    to_address1: _.get(parcel, 'recipientDetails.address'),
    to_address2: _.get(parcel, 'recipientDetails.address2', ''),
    to_postcode: _.get(parcel, 'recipientDetails.postCode', ''),
    to_state: _.get(parcel, 'location.state', ''),
    to_city: _.get(parcel, 'location.city', ''),
    to_locality: _.get(parcel, 'location.district', ''),
    to_latitude: _.get(parcel, 'location.lat'),
    to_longitude: _.get(parcel, 'location.lng'),
    to_country: country,
    delivery_instruction: _.get(parcel, 'comments'),
    pickup_instruction: _.get(parcel, 'description'),
    weight: _.get(parcel, 'weight'),
    insured_value: _.get(parcel, 'insurance') ? _.get(parcel, 'insuredValue') : 0,
    cod: _.get(parcel, 'codValue') !== undefined ? _.get(parcel, 'codValue') : null,
    service_level: SERVICE_LEVELS[serviceLevelKey],
    delivery_type: deliveryType,
    consignee_info_id: _.get(parcel, 'consigneeInfoId', null)
  }
}

export function getReturnOrderPayload (order: any, dpId: any, receiptId: any) {
  return {
    dp_id: dpId,
    receipt_id: receiptId,
    stamp_id: _.get(order, 'stampId.id'),
    from_name: _.get(order, 'fromName'),
    from_contact: _.get(order, 'fromContact'),
    from_email: _.get(order, 'fromEmail'),
    shipper_id: _.get(order, 'shipperId'),
    global_shipper_id: _.get(order, 'globalShipperId'),
    tracking_id: _.get(order, 'trackingId'),
    order_id: _.get(order, 'orderId'),
    to_name: _.get(order, 'toName'),
    to_email: _.get(order, 'toEmail'),
    to_contact: _.get(order, 'toContact'),
    to_address1: _.get(order, 'toAddress1'),
    to_address2: _.get(order, 'toAddress2'),
    to_city: _.get(order, 'toCity'),
    to_postcode: _.get(order, 'toPostcode')
  }
}

export function getOCManualPackParcelPayload (parcel: any, country: any) {
  return {
    to_name: _.get(parcel, PACK_FORM_FIELDS.recipientName),
    to_contact: _.get(parcel, PACK_FORM_FIELDS.recipientContact),
    to_email: _.get(parcel, PACK_FORM_FIELDS.recipientEmail),
    to_address1: _.get(parcel, PACK_FORM_FIELDS.address),
    to_locality: _.get(parcel, PACK_FORM_FIELDS.l3Label, ''),
    to_city: _.get(parcel, PACK_FORM_FIELDS.l2Label, ''),
    to_state: _.get(parcel, PACK_FORM_FIELDS.l1Label, ''),
    to_country: country,
    to_postcode: _.get(parcel, PACK_FORM_FIELDS.recipientPostCode, ''),
    to_latitude: _.get(parcel, PACK_FORM_FIELDS.latitude),
    to_longitude: _.get(parcel, PACK_FORM_FIELDS.longitude),
    pack_description: _.get(parcel, PACK_FORM_FIELDS.description)
  }
}

export function getReturnType (type: any) {
  switch (type) {
    case ID_TYPES.fullyIntegratedReturnId:
      return 'integrated'
    case ID_TYPES.semiIntegratedReturnId:
      return 'semi-integrated'
    case ID_TYPES.unintegratedReturnId:
      return 'unintegrated'
    default:
      return null
  }
}
function getSenderDetailsFromOrder (order) {
  return {
    name: _.get(order, 'fromName'),
    liaisonEmail: _.get(order, 'fromEmail'),
    liaisonContact: _.get(order, 'fromContact'),
    liaisonAddress: _.get(order, 'fromAddress1'),
    liaisonAddress2: _.get(order, 'fromAddress2'),
    liaisonPostcode: _.get(order, 'fromPostcode')
  }
}
function getRecipientDetailsFromOrder (order, country) {
  return {
    name: _.get(order, 'toName'),
    email: _.get(order, 'toEmail'),
    contact: normalizePhoneNumber(_.get(order, 'toContact'), country),
    address: _.get(order, 'toAddress1'),
    address2: _.get(order, 'toAddress2'),
    postCode: _.get(order, 'toPostcode')
  }
}

export function getTotalFromInvoicedAmountDetails (order: any) {
  const invoicedAmountDetails = _.get(order, 'pricingInfo.invoicedAmountDetails.result', null)
  let total
  if (invoicedAmountDetails) {
    const cod = _.get(invoicedAmountDetails, 'codFee')
    total = invoicedAmountDetails.totalWithTax - (_.get(cod, 'amount', 0) + _.get(cod, 'taxAmount', 0))
  } else {
    total = _.get(order, 'pricingInfo.total')
  }
  return total
}

// The value returned should match the grand total shown as on the receipt. It should be the sum of delivery fee, insurance fee, insurance tax, COD tax and COD fees.
export function getTotalWithTaxFromInvoicedAmountDetails (order: any) {
  const invoicedAmountDetails = _.get(order, 'pricingInfo.invoicedAmountDetails.result', null)
  return _.get(invoicedAmountDetails, 'totalWithTax', 0)
}

// eslint-disable-next-line max-len
export function getPricingDetailsFromOrder (order: any, country: any, includeCodValue: any, includeCurrencySymbol: any) {
  const { invoicedAmountDetails } = order.pricingInfo
  const pricingDetails = {}

  // check whether it needs to be transformed with currency details
  const transform = includeCurrencySymbol ? res => FormatOrderCost(country, res) : res => res

  if (!invoicedAmountDetails) {
    // invoiced amount details doesn't exist

    pricingDetails.insurance = transform(_.get(order, 'pricingInfo.insuranceFee', 0))
    pricingDetails.deliveryFee = transform(
      _.get(order, 'pricingInfo.total', 0) - _.get(order, 'pricingInfo.insuranceFee', 0)
    )
    pricingDetails.total = transform(_.get(order, 'pricingInfo.total', 0))
  } else {
    // invoiced amount details exist

    // populate pricing details
    pricingDetails.insurance = transform(
      _.get(invoicedAmountDetails, 'insuranceFee.amount', 0) + _.get(invoicedAmountDetails, 'insuranceFee.taxAmount', 0)
    )
    pricingDetails.deliveryFee = transform(
      _.get(invoicedAmountDetails, 'deliveryFee.amount', 0) + _.get(invoicedAmountDetails, 'deliveryFee.taxAmount', 0)
    )
    pricingDetails.cod = invoicedAmountDetails.codFee
    pricingDetails.total = transform(
      invoicedAmountDetails.totalWithTax -
        (_.get(pricingDetails.cod, 'amount', 0) + _.get(pricingDetails.cod, 'taxAmount', 0))
    )
    pricingDetails.codAmount = transform(
      _.get(invoicedAmountDetails, 'codFee.amount', 0) + _.get(invoicedAmountDetails, 'codFee.taxAmount', 0)
    )
  }

  // include cod value
  if (includeCodValue) {
    pricingDetails.codValue = transform(_.get(order, 'goodsAmount', 0.0))
    pricingDetails.reservationCod = transform(_.get(order, 'cod', 0.0))
    pricingDetails.reservationCodCollected = transform(_.get(order, 'codCollected', 0.0))
  }

  return pricingDetails
}

function getParcelDetailsFromOrder (order, country) {
  const parcel = {}
  parcel.codValue = _.get(order, 'goodsAmount', 0.0)
  parcel.cod = parcel.codValue > 0
  const deliveryTypeKey = _.invert(SERVICE_LEVELS)[_.get(order, 'orderServiceType')]
  parcel.cost = getPricingDetailsFromOrder(order)
  parcel.insuredValue = _.get(order, 'insurance')
  parcel.insurance = _.get(order, 'insurance') > 0
  parcel.location = {
    state: _.get(order, 'toState'),
    city: _.get(order, 'toCity'),
    district: _.get(order, 'toDistrict')
  }
  parcel.deliveryType = DELIVERY_TYPES[deliveryTypeKey]
  parcel.comments = _.get(order, 'lastDdnt.instruction')
  parcel.weight = _.get(order, 'weight')
  parcel.description = _.get(order, 'lastPpnt.instruction')
  parcel.stampId = { id: _.get(order, 'stampId'), isVerified: true }
  parcel.trackingId = _.get(order, 'trackingId')
  parcel.recipientDetails = getRecipientDetailsFromOrder(order, country)

  return parcel
}

export function formatOrder (order: any, country: any) {
  const formattedOrder = {}

  formattedOrder.source = _.get(order, 'source')
  formattedOrder.orderId = _.get(order, 'orderId')
  formattedOrder.batchId = _.get(order, 'batchId')
  formattedOrder.receiptId = _.get(order, 'receiptId')
  formattedOrder.status = _.get(order, 'status')
  formattedOrder.senderDetails = getSenderDetailsFromOrder(order)
  formattedOrder.parcels = [getParcelDetailsFromOrder(order, country)]

  return formattedOrder
}

const numericKeys = ['cod', 'weight', 'insured_value']
const uppercaseKeys = ['stamp_id', 'delivery_type']
export function getBulkOCPayload (data: any, positionToFieldMap: any) {
  const payload = {}
  data.forEach((d, index) => {
    const key = _.get(positionToFieldMap, index)
    if (_.includes(numericKeys, key)) {
      _.set(payload, key, parseFloat(parseFloat(d).toFixed(2)))
    } else {
      _.set(payload, key, _.includes(uppercaseKeys, key) ? _.toUpper(d) : d)
    }
  })
  return payload
}

const common = [
  'insuredValue',
  'l1Name',
  'l2Name',
  'l3Name',
  'parcelWeight',
  'stampId',
  'parcelDescription',
  'parcelComments',
  'recipientName'
]

const saveForLater = [...common, 'deliveryType', 'insurance']
const recipientExtras = ['recipientContact', 'recipientEmailAddress', 'recipientAddress', 'recipientPostCode']
export const FORM_FIELDS = {
  common: [...common, ...recipientExtras],
  saveForLater,
  orderConfirm: [...saveForLater, ...recipientExtras]
}

export function isValidBarcode (barcode: any) {
  if (_.isString(barcode) && /STAMP/i.test(barcode)) {
    return /^[a-zA-Z\d]+$/.test(barcode)
  }
  return _.isString(barcode) && /^[a-zA-Z\d-]+$/.test(barcode)
}

export function isPackId (trackingId: any, country: any) {
  return isEqual(trackingId.substring(0, 5).toLowerCase(), `nin${country.toLowerCase()}`)
}

export function getExpectedDeliveryDate (order: any) {
  const endTime = _.get(order, 'lastDdnt.endTime', null)
  return moment(endTime, 'YYYY-MM-DDTHH:mm:ssZ').format(DATE_FORMAT.DAY_MONTH_YEAR_SLASHED)
}

export const appendCountryCodeToNumber = (number: any, country: any) => {
  if (number && country) {
    const phonePrefix = getCountryData(country, COUNTRY_DATA_KEYS.phonePrefix)
    const countryCodePattern = new RegExp('^' + phonePrefix.replace('+', '\\+?'), 'i')

    if (countryCodePattern.test(number)) {
      return number.startsWith('+') ? number : '+'.concat(number)
    }

    return phonePrefix.concat(number)
  }
  return number
}

export const getPartyDetailsKeysByOrderType = orderType => {
  const PARTY_DETAILS_KEYS = [
    { key: 'Name', textContentKey: ['Name'] },
    { key: 'Contact', icon: faPhone, textContentKey: ['Contact'] },
    { key: 'Email', icon: faEnvelope, textContentKey: ['Email'] }
  ]

  const RECIPIENT_ADDRESS_DETAILS = {
    key: 'Address1',
    icon: faMapMarkerAlt,
    textContentKey: ['Address1', 'Address2']
  }

  if (isEqual(orderType, ORDER_TYPES.post)) {
    RECIPIENT_ADDRESS_DETAILS.textContentKey = RECIPIENT_ADDRESS_DETAILS.textContentKey.concat([
      'State',
      'City',
      'District',
      'Postcode'
    ])
  }

  PARTY_DETAILS_KEYS.push(RECIPIENT_ADDRESS_DETAILS)
  return PARTY_DETAILS_KEYS
}

export const isValidOrderTypeForCountry = (dpInfo, type) => {
  const { country, dpServiceType } = dpInfo

  switch (country.toLowerCase()) {
    case COUNTRIES.TH:
      switch (type) {
        case ORDER_TYPES.send:
          return true
        case ORDER_TYPES.post:
        case ORDER_TYPES.bulkPost:
          if (isEqual(dpServiceType, 'FRANCHISEE')) return true
          return false
        default:
          return true
      }
    case COUNTRIES.SG:
      return true
    case COUNTRIES.MY:
      return true
    case COUNTRIES.ID:
      return true
    case COUNTRIES.PH:
      return true
    case COUNTRIES.VN:
      return true
    default:
      return true
  }
}

/**
 * Returns the number of unique shippers in the bulk POST OC CSV file
 *
 * @param {Array} data - List of CSV column headers and all CSV row data
 */
export const getUniqueShipperCountFromBulkPostCsv = data => {
  const uniqueShippers = new Set()
  data.forEach(row => {
    const shipperId = row[0] // shipper id is in first column of csv file
    uniqueShippers.add(shipperId)
  })

  return uniqueShippers.size - 1 // Remove "SHIPPER ID" column header
}

/**
 * Takes in an array of orders and returns if first order is a staging order.
 * First order is sufficient to determine if order is staging.
 * @param {Array} orders array of Orders object
 * @returns true if order is a staging order, and false otherwise
 */
export const isStagingOrder = orders => {
  const orderStatus = _(orders).get('[0].status', ORDER_STATUS.default)
  return isEqual(orderStatus, ORDER_STATUS.staging)
}

export const toastConfig = (type, message, id = 'toast-container') => ({
  label: message,
  type: type,
  className: 'max-w-[500px]',
  position: 'topRight',
  appearanceTime: 0,
  closable: true,
  id: id
})
